import { Link } from "react-router-dom";
import { BsFacebook, BsTwitter, BsGithub, BsLinkedin } from "react-icons/bs";
import skull from "assets/images/skull.png";

const Footer = () => {
  return (
    <div className="p-4 bg-primary-black text-primary-white flex flex-col items-center md:flex-row h-auto">
      <div className="w-full md:w-2/5  flex flex-row md:flex-col items-center">
        <div>
          <div className="mb-4">
            <h1 className="text-[1.8rem]">BespectacledDev</h1>
          </div>
          <div className="my-2">
            <form action="" method="post">
              <input
                className="px-3 py-2 bg-transparent border border-gray-400"
                type="text"
                name=""
                id=""
                placeholder="Join my mail list"
              />
            </form>
          </div>
          <div className="my-3 pt-4 md:pt-0">
            <p className="text-sm font-bold mb-2">Connect with me</p>
            <div className="flex flex-row">
              <Link to="">
                <BsFacebook
                  size="20px"
                  className=" text-gray-500 mr-3 cursor-pointer"
                />
              </Link>
              <Link to="">
                <BsTwitter
                  size="20px"
                  className=" text-gray-500 mr-3 cursor-pointer"
                />
              </Link>
              <Link to="">
                <BsGithub
                  size="20px"
                  className=" text-gray-500 mr-3 cursor-pointer"
                />
              </Link>
              <Link to="">
                <BsLinkedin
                  size="20px"
                  className=" text-gray-500 mr-3 cursor-pointer"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-2/5 flex flex-row pt-5 md:pt-2">
        <div className="w-1/2  ">
          <p className="font-bold">Website</p>
          <div className="mt-3">
            <Link className="flex w-max" to="/">
              <p className="py-[3px] text-gray-400 hover:text-primary-white ease-in-out duration-200 text-sm">
                Home
              </p>
            </Link>
            <Link className="flex w-max" to="/about">
              <p className="py-[3px] text-gray-400 hover:text-primary-white ease-in-out duration-200 text-sm">
                About
              </p>
            </Link>
            <Link className="flex w-max" to="/contact">
              <p className="py-[3px] text-gray-400 hover:text-primary-white ease-in-out duration-200 text-sm">
                Contact
              </p>
            </Link>
          </div>
        </div>
        <div className="w-full ">
          <p className="font-bold">Discover</p>
          <div className="mt-3">
            <Link className="flex w-max" to="/">
              <p className="py-[3px] text-gray-400 hover:text-primary-white ease-in-out duration-200 text-sm">
                Who is BespectacledDev
              </p>
            </Link>
            <Link className="flex w-max" to="/blog">
              <p className="py-[3px] text-gray-400 hover:text-primary-white ease-in-out duration-200 text-sm">
                The Articles
              </p>
            </Link>
            <Link className="flex w-max" to="/blog">
              <p className="py-[3px] text-gray-400 hover:text-primary-white ease-in-out duration-200 text-sm">
                The Journey to Tech
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden md:flex w-1/5  justify-center items-center">
        <div>
          <img src={skull} alt="this is my prof" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
