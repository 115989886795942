import SampleImage from "assets/images/image1.jpg";
import AsideBar from "components/AsideBar";
import { getMonth } from "utils/formatDate";
import { Link } from "react-router-dom";
import useTrimWords from "hooks/useTrimWords";

const LatestSection = ({ posts, isLoaded, loadMore, nextPage }) => {
  const ItemComponent = ({ text, limit }) => {
    const trimmedText = useTrimWords(text, limit);
    return <>{trimmedText}</>;
  };

  return (
    <section className="flex flex-col w-full relative md:flex-row-reverse h-auto pt-6 ">
      <AsideBar />
      {posts && (
        <div className="relative h-auto md:basis-3/4  px-4 md:pl-4 mx-4 md:mx-10 mb-6 md:mb-2 md:max-w-[60vw]">
          {posts.map((item, index) => {
            return (
              <div
                className="flex flex-col-reverse py-2 w-full md:flex-row md:py-6 mr-4 mb-2 md:min-w-full justify-between"
                key={index}
              >
                <div className="mt-2 md:mt-0 flex-2 pr-4 mr-1  md:h-auto md:min-w-[60%]">
                  <div className="py-1 flex items-center">
                    <Link
                      className="flex items-center"
                      to={`/blog/${item.topic.topicName.replace(/\s/g, "-")}`}
                    >
                      <div className="h-3 w-3 rounded-full mr-2 bg-primary-red"></div>
                      <p className="text-xs font-bold">
                        {item.topic.topicName}
                      </p>
                    </Link>
                  </div>
                  <div className="text-lg">
                    <Link
                      className="flex items-center"
                      to={`/blog/${item.topic.topicName.replace(/\s/g, "-")}/${
                        item.postId
                      }`}
                    >
                      <h3 className="font-semibold">{item.postTitle}</h3>
                    </Link>
                  </div>
                  <div className="py-1 text-gray-700 w-full font-semibold">
                    <Link
                      className="flex items-center"
                      to={`/blog/${item.topic.topicName.replace(/\s/g, "-")}/${
                        item.postId
                      }`}
                    >
                      <p className="md:text-[1rem] font-lato font-normal">
                        <ItemComponent text={item.postDescription} limit={15} />
                      </p>
                    </Link>
                  </div>
                  <div className="py-1 text-xs text-gray-500">
                    <span className="mr-1">{getMonth(item.createdAt)}</span>
                    {/* <span className="px-[0.1rem]">.</span> */}
                    <span className="ml-1">{item.readingTime} min read</span>
                  </div>
                </div>
                <div className="w-full md:max-w-[230px] h-auto">
                  <img
                    className="h-full w-full md:w-72 object-cover"
                    src={item.postHeaderImage}
                    alt="cardimage"
                  />
                </div>
              </div>
            );
          })}
          <div className="flex justify-center mb-5">
            {nextPage !== null && (
              <button
                onClick={loadMore}
                className="px-5 py-2 border rounded-full bg-primary-white hover:bg-gray-200 shadow-sm font-lato text-gray-700 hover:text-primary-black duration-100"
              >
                Load more
              </button>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default LatestSection;
