import React, { useEffect, useState } from "react";

const CheckBoxList = ({ subtopics, onChange, loadedSubtopics }) => {
  const [checkedSubtopics, setCheckedSubtopics] = useState([]);

  useEffect(() => {
    if (loadedSubtopics) {
      setCheckedSubtopics(loadedSubtopics);
    }
  }, [loadedSubtopics]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setCheckedSubtopics((prevCheckedSubtopics) => {
      const updatedSubtopics = { ...prevCheckedSubtopics };

      if (checked) {
        updatedSubtopics[value] = subtopics[value];
      } else {
        delete updatedSubtopics[value];
      }

      return updatedSubtopics;
    });
  };

  React.useEffect(() => {
    onChange(checkedSubtopics);
  }, [checkedSubtopics]);

  return (
    <div className="flex flex-row overflow-scroll">
      {Object.entries(subtopics).map(([subTopicId, subtopic]) => (
        <label
          key={subTopicId}
          className="px-4 py-2 border min-w-max bg-gray-100 hover:bg-gray-200 rounded-full mr-2"
        >
          <input
            type="checkbox"
            value={subTopicId}
            checked={checkedSubtopics.hasOwnProperty(subTopicId)}
            onChange={handleCheckboxChange}
            className="mr-2"
          />
          {subtopic.subTopicName}
        </label>
      ))}
    </div>
  );
};

export default CheckBoxList;
