import { Link } from "react-router-dom";
import { TiArrowRight } from "react-icons/ti";
import axios from "axios";

import image from "assets/images/image1.jpg";
import Popular from "./popular";
import { formatFullDate } from "utils/formatDate";
import { useState, useEffect } from "react";
import axiosClient from "api/axios";
import PreLoader from "components/Preloader";

const AllTopics = () => {
  const [topicsListAndPosts, settopicsListAndPosts] = useState();

  useEffect(() => {
    const fetchTopics = async () => {
      await axiosClient
        .get("/api/v1/topics?published[eq]=1&include-posts=true")
        .then(({ data }) => {
          settopicsListAndPosts(data.data);
        })
        .catch((e) => {});
    };
    fetchTopics();
  }, []);

  return (
    <div className=" pt-16">
      <div>
        {/* --------------------------Implement this whenfinished analytics--------------- */}
        {/* <Popular /> */}
      </div>

      <div className="md:px-32 px-[5vw]">
        {!topicsListAndPosts && <PreLoader />}
        {topicsListAndPosts &&
          topicsListAndPosts.map((item, index) => {
            if (item.posts.length > 0) {
              return (
                <div key={index}>
                  <div className="py-5 flex flex-row justify-between border-b-2 border-b-primary-black text-xs font-bold">
                    <Link
                      to={`/blog/${item.topicName.replace(/\s/g, "-")}?topic=${
                        item.topicId
                      }`}
                    >
                      <p className="font-bold text-primary-red text-sm">
                        {item.topicName}
                      </p>
                    </Link>

                    <Link
                      to={`/blog/${item.topicName.replace(/\s/g, "-")}?topic=${
                        item.topicId
                      }`}
                      className="flex items-center"
                    >
                      <p className=" font-bold hover:text-gray-500 ease-in-out duration-100">
                        SEE ALL
                      </p>
                      <TiArrowRight className="ml-1 text-[1.2rem]" />
                    </Link>
                  </div>
                  <div className="py-7 flex flex-col md:flex-row w-full">
                    {item.posts.slice(0, 3).map((items, index) => {
                      let date = formatFullDate(items.createdAt);
                      return (
                        <div key={index} className="md:w-1/3 px-5 py-3">
                          <div>
                            <Link
                              to={`/blog/${item.topicName.replace(
                                /\s/g,
                                "-"
                              )}/${items.postId}`}
                            >
                              <div className="w-full h-56 bg-cover">
                                <img
                                  className="h-full w-full object-cover object-center"
                                  src={items.postHeaderImage}
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                            </Link>
                            <Link
                              to={`/blog/${item.topicName.replace(
                                /\s/g,
                                "-"
                              )}/${items.postId}`}
                            >
                              <div className="mt-2">
                                <h5 className="font-bold text-gray-700">
                                  {items.postTitle}
                                </h5>
                              </div>
                            </Link>
                            <Link
                              to={`/blog/${item.topicName.replace(
                                /\s/g,
                                "-"
                              )}/${items.postId}`}
                            >
                              <div>
                                <p className="text-sm">
                                  {items.postDescription}
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="text-xs mt-2 flex fllex-row justify-between font-merriweathersans">
                            <div>
                              <span className="text-gray-500">{date}</span>
                              <span className="pl-2">
                                {items.user.firstName} {items.user.lastName}
                              </span>
                            </div>
                            <div>
                              <p>Share</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};

export default AllTopics;
