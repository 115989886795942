//import "./Nav.css";
import { Link } from "react-router-dom";
import { useScrollPosition } from "hooks/useScrollPosition";
//import { TiUser } from "react-icons/ti";
import { HiMenu } from "react-icons/hi";
// import { CgClose } from "react-icons/cg";
import { useState } from "react";
import MenuButton from "./MenuButton";
import { useUserStateContext } from "contexts/UserContext";

function Nav() {
  const scrollPosition = useScrollPosition();
  const [click, setClick] = useState(false);

  function navClassNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleMenuBtnClick = () => {
    setClick(!click);
  };
  const handleMenuItemClick = () => {
    setClick(false);
  };

  const { isAuth } = useUserStateContext();
  return (
    <div
      className={navClassNames(
        scrollPosition > 250
          ? " bg-white shadow-md fixed grid md:px-10 w-full text-primary-black h-16 content-center z-50 ease-in-out duration-200"
          : " bg-primary-black shadow-md fixed grid md:px-10 w-full text-primary-white h-16 content-center z-50 ease-in-out duration-200"
      )}
    >
      <nav className="flex justify-between md:justify-around items-center z-50 relative px-10">
        <div>
          <Link to="/" onClick={handleMenuItemClick}>
            <div className="flex w-max">BespectacledDev</div>
          </Link>
        </div>
        <div className="md:hidden flex">
          <HiMenu
            size="30px"
            className="cursor-pointer"
            onClick={handleMenuBtnClick}
          />
          <div className="ease-in-out duration-300 ">
            {click && (
              <>
                <div
                  className="absolute w-full h-[100vh] top-[6.5vh] right-0 bg-gradient-to-t from-background-dark-0 to-black"
                  onClick={handleMenuItemClick}
                ></div>

                <div
                  // className={navClassNames(
                  //   click
                  //     ? "ease-in-out duration-300 absolute border border-none h-[50vh] w-[100vw] left-0 top-12 z-10 bg-white"
                  //     : "ease-in-out duration-300 absolute border border-none h-[50vh] w-[100vw] left-0 top-12 z-10 bg-primary-black"
                  // )}
                  className="ease-in-out duration-300 absolute border border-none h-auto w-[90vw] left-1/2 transform -translate-x-1/2  top-14 z-10 rounded-md shadow-xl py-4 box bg-primary-black text-primary-white"
                >
                  <ul className="flex w-full flex-col items-center">
                    <li className="px-4 py-3  hover:text-gray-500 ease-in-out duration-150">
                      <Link to="/blog" onClick={handleMenuItemClick}>
                        Blog
                      </Link>
                    </li>
                    <li className="px-4 py-3 hover:text-gray-500 ease-in-out duration-150">
                      <Link to="/blog/tech" onClick={handleMenuItemClick}>
                        Tech
                      </Link>
                    </li>
                    <li className="px-4 py-3 hover:text-gray-500 ease-in-out duration-150">
                      <Link to="/blog/dev" onClick={handleMenuItemClick}>
                        Dev
                      </Link>
                    </li>
                    <li className="px-4 py-3 hover:text-gray-500 ease-in-out duration-150">
                      <Link
                        to="/blog/modelling-design"
                        onClick={handleMenuItemClick}
                      >
                        Modelling Design
                      </Link>
                    </li>
                    <li className="px-4 py-3 hover:text-gray-500 ease-in-out duration-150">
                      <Link to="/about" onClick={handleMenuItemClick}>
                        About
                      </Link>
                    </li>
                    <li className="px-4 py-3 hover:text-gray-500 ease-in-out duration-150">
                      <Link to="/contact" onClick={handleMenuItemClick}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="hidden md:flex md:justify-between  ">
          <div className="flex w-max justify-center items-center content-center">
            <ul className="flex w-full ">
              <li className="px-4">
                <Link to="/blog">Blog</Link>
              </li>
              {/* <li className="px-4">
                <Link to="/blog/tech">Tech</Link>
              </li>
              <li className="px-4">
                <Link to="/blog/dev">Dev</Link>
              </li>
              <li className="px-4">
                <Link to="/blog/modelling-design">Modelling Design</Link>
              </li> */}
            </ul>
          </div>
          <div className="flex">
            <ul className=" flex justify-between items-center">
              <li className="px-4">
                <Link to="about">About</Link>
              </li>
              <li className="px-4">
                <Link to="contact">Contact</Link>
              </li>
              {isAuth && (
                <li>
                  <div>
                    {/* <Link to="high-level/my-admin">
                  <TiUser
                    size="30px"
                    className="text-primary-blue cursor-pointer"
                  />
                </Link> */}
                    <MenuButton />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
