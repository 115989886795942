import axiosClient from "api/axios";
import { useRef, useState } from "react";
import { AppStateContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const { setNotification } = AppStateContext();
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const emailRef = useRef();
  const subjectRef = useRef();
  const messageRef = useRef();

  const handleEmailRequestSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: emailRef.current.value,
      subject: subjectRef.current.value,
      message: messageRef.current.value,
    };

    await axiosClient
      .post("/api/v1/info-email", payload)
      .then((response) => {
        emailRef.current.value = "";
        subjectRef.current.value = "";
        messageRef.current.value = "";
        setNotification(response.data.message);
        navigate("/contact");
      })
      .catch((error) => {
        console.log(error);
        setErrorMsg("Error sending email. Try again.");
      });
  };
  return (
    <div className="min-h-[70vh] pt-16  mb-5 mx-16 divide-y divide-gray-200 dark:divide-gray-300">
      <div className=" space-y-2 pt-6 pb-8 md:space-y-5">
        <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-800 sm:text-4xl sm:leading-10 md:text-6xl">
          Contact
        </h1>
      </div>
      <div className="items-start space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
        <div className="flex-row font-oxygen text-gray-800 justify-center items-center prose max-w-none pt-8 pb-8 dark:prose-dark xl:col-span-2">
          {errorMsg ? <p className="error-msg">{errorMsg}</p> : <p></p>}
          <form onSubmit={handleEmailRequestSubmit}>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 font-medium text-gray-900 "
              >
                Your email address
              </label>
              <input
                type="text"
                id="email"
                className="bg-gray-50 border border-gray-300 focus:outline-none text-gray-900 text-sm rounded-lg block w-full md:w-3/4 p-2.5  dark:border-gray-300 dark:placeholder-gray-400 "
                placeholder="Enter email"
                required
                ref={emailRef}
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="subject"
                className="block mb-2 font-medium text-gray-900 "
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="bg-gray-50 border border-gray-300 focus:outline-none text-gray-900 text-sm rounded-lg block w-full md:w-3/4 p-2.5  dark:border-gray-300 dark:placeholder-gray-400 "
                placeholder="Enter subject"
                required
                ref={subjectRef}
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="message"
                className="block mb-2 font-medium text-gray-900 "
              >
                Your message
              </label>
              <textarea
                type="text"
                id="message"
                className="bg-gray-50 border border-gray-300 focus:outline-none text-gray-900 text-sm rounded-lg block w-full md:w-3/4 p-2.5  dark:border-gray-300 dark:placeholder-gray-400 "
                placeholder="Type message here"
                required
                ref={messageRef}
              />
            </div>
            <button
              type="submit"
              className="text-white bg-primary-blue hover:bg-bg-footer-blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-primary-blue dark:hover:bg-footer-blue dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
