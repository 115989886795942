import axiosClient from "./axios";

const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 800; // Adjust as needed
        const MAX_HEIGHT = 600; // Adjust as needed
        let width = img.width;
        let height = img.height;

        // Perform resizing if needed
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }

        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw image on canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // Convert canvas content to base64 data URL
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          1
        ); // Adjust JPEG quality as desired
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  });
};

const apiPostImage = async (formData) => {
  try {
    const compressedFile = await compressImage(formData.get("image"));
    formData.set("image", compressedFile, "compressed.jpg");
    const response = await axiosClient.post("/api/v1/image-upload", formData);
    return response;
  } catch (error) {
    console.error(error);
    throw new Error("Image upload failed.");
  }
};

export default apiPostImage;
