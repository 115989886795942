import HeaderImage from "assets/images/image2.jpg";

const Hero = () => {
  return (
    <div className="block relative max-h-[70vh] md:min-h-[80vh] overflow-hidden bg-background-dark">
      <div className="absolute top-0 right-0 w-[70%] h-[60vh] md:h-[60vh] overflow-hidden z-1">
        <img
          className="w-full h-full object-cover object-center"
          src={HeaderImage}
          alt=""
        />
        <div className="absolute w-full h-full top-0 right-0 bg-gradient-to-t from-background-dark-0 to-black"></div>
      </div>
      <div className="relative h-[70vh] ml-[5vw] md:h-[80vh] flex items-start justify-center z-2 md:ml-[10vw] text-primary-white flex-col">
        <div className="w-full md:max-w-[50vw] h-auto text-[2rem] md:text-[3.5rem] underline font-bold">
          <h1>How my love for tech began, with a simple line</h1>
        </div>
        <div className="flex items-center mt-3">
          <div className="h-6 w-6 mr-2 bg-background-aqua"></div>
          <p>John Mugambi</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
