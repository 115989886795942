import React, { useState, useEffect } from "react";

const useTrimWords = (text, limit) => {
  const [trimmedText, setTrimmedText] = useState("");

  useEffect(() => {
    if (text) {
      const words = text.split(" ");
      if (words.length > limit) {
        const trimmedWords = words.slice(0, limit);
        const trimmedContent = trimmedWords.join(" ") + "...";
        setTrimmedText(trimmedContent);
      } else {
        setTrimmedText(text);
      }
    }
  }, [text, limit]);

  return trimmedText;
};

export default useTrimWords;
