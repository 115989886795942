import axiosClient from "api/axios";
import apiPostImage from "api/useApiPostImage";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// Local/internal imports
import EditorContainer from "utils/EditorContainer";
import CheckBoxList from "../components/subtopicscheckox";
import { useUserStateContext } from "contexts/UserContext";

const EditPost = () => {
  //make get req
  //get id from url

  const [topic_id] = useSearchParams();
  const navigate = useNavigate();
  const id = topic_id.get("post_id");

  const { user } = useUserStateContext();

  const [postTitle, setPostTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  const [postBody, setPostBody] = useState("");
  const [creator, setCreator] = useState(user?.user_id || null);
  const [displayError, setDisplayError] = useState("");

  const [success, setSuccess] = useState("");

  const [selectedTopic, setSelectedTopic] = useState();
  //get list of topics for the select
  const [topics, setTopics] = useState();
  const [isLoadedTopics, setIsLoadedTopics] = useState(false);

  //file upload
  const [file, setFile] = useState();

  //handle file
  const [imageFileChange, setImageFileChange] = useState(false);

  const handleFileChange = (e) => {
    setImageFileChange(true);
    setFile(e.target.files[0]);
    //setFile(URL.createObjectURL(e.target.files[0]));
    //console.log(URL.createObjectURL(e.target.files[0]));
  };

  const [subTopics, setSubTopics] = useState();
  const [loadedSubtopics, setLoadedSubtopics] = useState();

  //fetch subtopics details
  useEffect(() => {
    const fetchSubTopics = async () => {
      axiosClient
        .get(`/api/v1/subtopics?topicId[eq]=${selectedTopic}`)
        .then(({ data }) => {
          setSubTopics(data.data);
        })
        .catch((error) => {
          //console.log(error);
          const { response } = error;
          setDisplayError(response.data.error);
        });
    };

    fetchSubTopics();
  }, [selectedTopic]);

  useEffect(() => {
    const fetchPosts = async () => {
      axiosClient
        .get(`/api/v1/posts/${id}`)
        .then(({ data }) => {
          setFile(data.data.postHeaderImage);
          setPostTitle(data.data.postTitle);
          setPostBody(data.data.postBody);
          setDescription(data.data.postDescription);
          setSelectedTopic(data.data.topicId);
          setKeywords(data.data.postKeywords);
          setLoadedSubtopics(JSON.parse(data.data.subtopics));
          setImageFileChange(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    fetchPosts();

    try {
      axiosClient.get("/api/v1/topics").then(({ data }) => {
        setTopics(data.data);
        setIsLoadedTopics(true);
      });
    } catch (error) {
      setIsLoadedTopics(false);
      setDisplayError(error.message);
    }
  }, []);

  const [selectedItems, setSelectedItems] = useState();

  const handleSelectedItemsChange = (checkedItems) => {
    setSelectedItems(checkedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let postHeaderImageUrl = null;
    setCreator(user.user_id);
    if (imageFileChange) {
      try {
        const formData = new FormData();

        formData.append("image", file);

        const res = await apiPostImage(formData);

        if (res) {
          postHeaderImageUrl = res.data.url;
        }
      } catch (error) {}
    } else {
      postHeaderImageUrl = file;
    }
    // const contents = postBody;

    // console.log(contents);
    // const post_sections = [];

    // contents.forEach((op) => {
    //   if (op.insert) {
    //     if (typeof op.insert === "string") {
    //       post_sections.push({ type: "text", text: op.insert });
    //     } else {
    //       post_sections.push({
    //         type: "code",
    //         content: op.insert.code,
    //         language: op.insert.language,
    //       });
    //     }
    //   }
    // });

    const data = {
      postTitle: postTitle,
      postHeaderImage: postHeaderImageUrl,
      postDescription: description,
      postKeywords: keywords,
      postBody: postBody,
      topicId: selectedTopic,
      userId: creator,
      subTopics: JSON.stringify(selectedItems),
    };

    setDisplayError(null);
    setSuccess(null);

    try {
      await axiosClient
        .put(`/api/v1/posts/${id}`, data)
        .then((response) => {
          if (response) {
            navigate("/high-level/my-admin/view-post?published=false");
          }
        })
        .catch((error) => {
          setDisplayError(error.message);
        });
    } catch (error) {
      setDisplayError(error.message);
    }
  };

  return (
    <div className="flex flex-col">
      <div>
        {displayError && <div className="error-msg">{displayError}</div>}
        {success && <div className="success-msg">{`${success}`}</div>}
      </div>{" "}
      <div className="border-b border-gray-400 pb-5">
        <h5 className="text-[1.5rem]">New Post</h5>
      </div>
      <form className="mt-6">
        <div className="mb-6">
          <label
            htmlFor="poat_title"
            className="block mb-2 font-medium text-gray-900 "
          >
            Title
          </label>
          <input
            type="text"
            id="post_title"
            value={postTitle}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter topic name"
            onChange={(e) => {
              setPostTitle(e.target.value);
            }}
            required
          />
        </div>

        {/* Post image */}

        <div className="">
          <label className="block mb-2 mr-3" htmlFor="post_header_image">
            Add header image
          </label>
          <input
            className="border bg-gray-100 shadow-sm p-2 rounded-md mb-6"
            type="file"
            name="post_header_image"
            id="post_header_image"
            accept="image/*"
            onChange={handleFileChange}
          />
          {file && (
            <img
              src={imageFileChange ? URL.createObjectURL(file) : file}
              alt="post header img"
            />
          )}
        </div>

        <div className="mb-6">
          <label
            htmlFor="topics"
            className="block mb-2  font-medium text-gray-900 "
          >
            Select your topic
          </label>
          <select
            id="topics"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => setSelectedTopic(e.target.value)}
            value={selectedTopic}
          >
            <option>Select topic</option>
            {isLoadedTopics &&
              topics.map((item, index) => {
                return (
                  <option key={index} value={item.topicId}>
                    {item.topicName}
                  </option>
                );
              })}
          </select>
        </div>

        <div className="mb-6 mt-6 h-auto w-full  ">
          <label
            htmlFor="topics"
            className="block mb-4  font-medium text-gray-900 mr-3"
          >
            Select sub -topics
          </label>
          {subTopics && (
            <CheckBoxList
              subtopics={subTopics}
              onChange={handleSelectedItemsChange}
              loadedSubtopics={loadedSubtopics}
            />
          )}
        </div>

        <div className="mb-6">
          <label
            htmlFor="post_description"
            className="block mb-2  font-medium text-gray-900 "
          >
            Description
          </label>
          <textarea
            id="post_description"
            rows="4"
            className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter description..."
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
          ></textarea>
        </div>
        <div className="mb-6">
          <label
            htmlFor="poat_title"
            className="block mb-2 font-medium text-gray-900 "
          >
            Post Keywords
          </label>
          <input
            type="text"
            id="post_keywords"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter post keyword name"
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
            value={keywords}
            required
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="message"
            className="block mb-2  font-medium text-gray-900 "
          >
            Body
          </label>
          <EditorContainer
            value={postBody}
            onChange={(value) => {
              setPostBody(value);
            }}
            placeholder="Enter post body"
          />
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="text-white bg-primary-black hover:bg-bg-footer-blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-primary-blue dark:hover:bg-footer-blue dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EditPost;
