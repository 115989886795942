import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { AppStateContext } from "contexts/AppContext";
import axiosClient from "api/axios";
import { useUserStateContext } from "contexts/UserContext";

const EditTopic = () => {
  //constext
  const { setNotification } = AppStateContext();
  const { user } = useUserStateContext();

  //get id from url
  const [topic_id] = useSearchParams();
  const navigate = useNavigate();
  const id = topic_id.get("topic_id");
  //make get req

  const [topicName, setTopicName] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState();
  const [creator, setCreator] = useState(user?.user_id || null);
  const [displayError, setDisplayError] = useState("");

  useEffect(() => {
    const fetchTopics = async () => {
      await axiosClient
        .get(`/api/v1/topics/${id}`)
        .then(({ data }) => {
          setDescription(data.data.topicDescription);
          setTopicName(data.data.topicName);
          setKeywords(data.data.topicKeywords);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    fetchTopics();
  }, []);

  const handleSubmit = async (e) => {
    setCreator(user.user_id);
    e.preventDefault();
    const data = {
      topicName: topicName,
      topicDescription: description,
      topicKeywords: keywords,
      createdBy: creator,
    };
    await axiosClient
      .put(`/api/v1/topics/${id}`, data)
      .then((response) => {
        if (response) {
          navigate("/high-level/my-admin/view-topic?published=true");
        }
        setNotification("Topic updated successfully");
      })
      .catch((error) => {
        setDisplayError(error.message);
        //console.log(error.message);
      });
  };
  return (
    <div className="flex flex-col">
      <div>
        {displayError && <div className="error-msg">{displayError}</div>}
      </div>
      <div className="border-b border-gray-400 pb-5">
        <h5 className="text-[1.5rem]">Topic Details</h5>
      </div>

      <form className="mt-6">
        <div className="mb-6">
          <label
            htmlFor="topic_name"
            className="block mb-2 font-medium text-gray-900 "
          >
            Name
          </label>
          <input
            type="text"
            id="topic_name"
            name="topic_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter topic name"
            value={topicName}
            onChange={(e) => {
              setTopicName(e.target.value);
            }}
            required
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="topic_description"
            className="block mb-2  font-medium text-gray-900 "
          >
            Description
          </label>
          <textarea
            type="text"
            name="topic_description"
            id="topic_description"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter topic description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block mb-2 font-medium text-gray-900 "
          >
            Keywords
          </label>
          <input
            type="text"
            id="topic_keywords"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 "
            placeholder="Enter topic keywords"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            required
          />
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          className="text-white bg-primary-blue hover:bg-bg-footer-blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-primary-blue dark:hover:bg-footer-blue dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EditTopic;
