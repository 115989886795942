import Footer from "components/Footer";
import Nav from "components/Nav";
import { AppStateContext } from "contexts/AppContext";

const MainLayout = ({ children }) => {
  const { notification } = AppStateContext();
  return (
    <div className="relative">
      <Nav />
      <div className="pt-0 relative  min-h-[70vh]">
        {notification && (
          <div className="notification-toast px-7 py-3 rounded-full">
            {notification}
          </div>
        )}
        <div>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
