import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { TiArrowRight } from "react-icons/ti";
import { useEffect, useState } from "react";

import AsideBar from "components/AsideBar";
import image from "assets/images/image1.jpg";
import { getMonth } from "utils/formatDate";

import axios from "axios";
import axiosClient from "api/axios";
import { Helmet } from "react-helmet-async";
import PreLoader from "components/Preloader";

const BlogTopic = ({ topic }) => {
  //get topic id from url and fetch results to populate page
  const [searchParams] = useSearchParams();
  const topicId = searchParams.get("topic");

  const navigate = useNavigate();

  const [topicsAndPosts, setTopicsAndPosts] = useState();
  const [topicName, setTopicName] = useState("");
  const [topicPosts, setTopicPosts] = useState([]);
  const [topicDisplayImage, setTopicDisplayImage] = useState();
  const [topicDescription, setTopicDescription] = useState();
  const [topicKeywords, setTopicKeywords] = useState();

  //fetch post of givn topic
  useEffect(() => {
    const fetchTopicAndPosts = () => {
      if (topicId) {
        axiosClient
          .get(`/api/v1/topics/${topicId}`)
          .then(({ data }) => {
            if (data) {
              setTopicsAndPosts(data.data);
              setTopicName(data.data.topicName);
              setTopicPosts(data.data.posts);
              setTopicDisplayImage(data.data.topicHeaderImage);
              setTopicDescription(data.data.topicDescription);
              setTopicKeywords(data.data.topicKeywords);
            } else {
              navigate("/blog");
            }
          })
          .catch((error) => {
            console.log(error);
            navigate("/blog");
          });
      } else {
        //console.log("this doesnt exist"); //navigate to 404
      }
    };
    fetchTopicAndPosts();
  }, [topicId]);

  return (
    <div className="min-h-[70vh] pt-16 relative w-full">
      {/* This is the header */}
      <Helmet>
        {topicDescription && (
          <>
            <meta name="description" content={topicDescription} />
            <meta name="keywords" content={topicKeywords} />
          </>
        )}
      </Helmet>
      {topicsAndPosts ? (
        <>
          <div className="relative overflow-hidden">
            <div className="absolute w-full h-full overflow-hidden">
              <img
                className="w-full max-h-[20vh] object-cover object-center"
                src={topicDisplayImage}
                alt=""
              />
              <div className="z-1 absolute w-full h-full top-0 right-0 bg-primary-black opacity-30"></div>
            </div>
            <div className="relative h-[75vh] md:h-[70vh] flex items-center justify-center z-2 px-3 md:pl-[10vw] text-primary-white flex-row">
              <div className="w-full md:w-4/6">
                <div className="flex items-center mt-3">
                  <div className="h-3 w-3 rounded-full mr-2 bg-primary-red"></div>
                  <p className="text-xs font-bold uppercase">{topicName}</p>
                </div>
                <div className="w-full md:max-w-[50vw] h-auto text-[2rem] md:text-[3rem] font-bold">
                  <h1>How my love of tech began, with a simple line</h1>
                </div>
                <div className="flex items-center mt-3">
                  <p className="text-xs">
                    <span>24.03.2022</span>
                    <span> by John Mugambi</span>
                  </p>
                </div>
              </div>
              <div className="hidden relative px-12 text-sm w-2/6 md:flex md:flex-col justify-center h-full ">
                <div className="z-40">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quasi qui rem accusamus voluptas, ea maxime optio tenetur,
                    laudantium, autem corrupti delectus velit dolores blanditiis
                    modi explicabo dolorem quam voluptatibus earum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quasi qui rem accusamus voluptas, ea maxime optio tenetur,
                    laudantium, autem corrupti delectus velit dolores blanditiis
                    modi explicabo dolorem quam voluptatibus earum.
                  </p>
                </div>
                <div className="mt-4 z-30 ">
                  <Link to="#" className="flex items-center">
                    <p className="text-sm font-bold hover:text-primary-blue ease-in-out duration-150 ">
                      Read More
                    </p>
                    <TiArrowRight className="ml-1 pt-1 text-[1.5rem] text-primary-red" />
                  </Link>
                </div>
                <div className="z-1 absolute w-full h-full top-0 right-0 bg-primary-black opacity-80"></div>
              </div>

              {/* This is the long story short section */}
            </div>
          </div>
          <div>
            <section className="relative flex flex-col md:flex-row-reverse h-auto pt-2 px-8 md:pt-6 md:mt-4 w-full ">
              <AsideBar />
              <div className="md:w-8/12 relative h-auto px-3 md:pl-10 mx-0 mb-2 flex flex-col w-full">
                <div className="">
                  <div className="py-3 md:py-5 flex flex-row justify-between border-b-2 border-b-primary-black text-xs font-bold text-primary-red">
                    <p className="uppercase">{topicName}</p>
                  </div>
                  {/* First section ----------------------- implement as popular articles in this topic*/}
                  {/* <div className=" flex flex-col md:flex-row w-full mt-4 md:mt-8">
                <div className="w-full md:w-7/12 ">
                  <div className="h-[40vh] md:h-full relative ">
                    <div className="w-full h-full">
                      <img
                        className="h-full w-full object-cover object-center"
                        src={image}
                        alt=""
                        srcSet=""
                      />
                    </div>
                    <div className="z-10 absolute w-5/6 text-center  top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                      <div className="md:mb-7">
                        <h5 className="font-bold text-primary-white text-[1rem] md:text-[1.5rem]">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </h5>
                      </div>
                      <div className="text-xs mt-2 md:mt-5">
                        <div>
                          <p className="text-gray-300">
                            April,2020,
                            <span className="text-primary-blue">
                              by John Mugambi
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="absolute w-full h-full top-0 right-0 bg-gradient-to-b from-background-dark-0 to-black opacity-90"></div>
                  </div>
                </div>
                <div className="w-full md:w-5/12 h-max md:pl-10 mt-5 md:mt-0">
                  {fourposts.map((index) => {
                    return (
                      <div
                        key={index}
                        className="card flex flex-row w-full mb-7 justify-center"
                      >
                        <div className="md:w-16 md:h-16 min-w-[60px] h-[60px]">
                          <img
                            className="h-full w-full object-cover object-center"
                            src={image}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <div className="md:w-[18vw]  flex md:flex-wrap flex-col justify-center md:justify-between pl-3">
                          <p className="text-sm font-bold md:flex-wrap">
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit.
                          </p>
                          <p className="text-xs">
                            23 April{" "}
                            <span className="uppercase ml-3">{topic}</span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}
                </div>
                <div className="mt-10 w-full flex flex-col">
                  {/* {topicPosts.map((post, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row py-6 mr-4 mb-2 min-w-full"
                  >
                    <div className="flex-2 pr-3 mr-1">
                      <div className="py-1">
                        <p className="text-xs font-bold">{topicName}</p>
                      </div>
                      <div className="text-lg">
                        <h3 className="font-semibold">{post.post_title}</h3>
                      </div>
                      <div className="py-1 text-gray-500 font-semibold">
                        <p>{post.post_description}</p>
                      </div>
                      <div className="py-1 text-xs text-gray-500">
                        <span>Sep 5</span>
                        <span>.</span>
                        <span>6 min read</span>
                      </div>
                    </div>
                    <div className=" w-72 h-auto">
                      <img
                        className="h-full w-72 object-cover"
                        src={image}
                        alt="cardimage"
                      />
                    </div>
                  </div>
                );
              })} */}
                  {topicPosts.map((item, index) => {
                    return (
                      <div
                        className="flex flex-col-reverse py-2 w-full md:flex-row md:py-6 mr-4 mb-2 md:min-w-full justify-between"
                        key={index}
                      >
                        <div className="mt-2 md:mt-0 flex-2 pr-4 mr-1  md:h-auto max-w-md">
                          <div className="py-1 flex items-center">
                            <Link
                              className="flex items-center"
                              to={`/blog/${topicName.replace(/\s/g, "-")}`}
                            >
                              <div className="h-3 w-3 rounded-full mr-2 bg-primary-red"></div>
                              <p className="text-sm font-bold font-lato">
                                {topicName}
                              </p>
                            </Link>
                          </div>
                          <div className="text-lg">
                            <Link
                              className="flex items-center"
                              to={`/blog/${topicName.replace(/\s/g, "-")}/${
                                item.postId
                              }`}
                            >
                              <h3 className="font-semibold">
                                {item.postTitle}
                              </h3>
                            </Link>
                          </div>
                          <div className="py-1 text-gray-700 w-full font-semibold">
                            <Link
                              className="flex items-center"
                              to={`/blog/${topicName.replace(/\s/g, "-")}/${
                                item.postId
                              }`}
                            >
                              <p className="md:text-[1rem] font-lato font-normal">
                                {item.postDescription}
                              </p>
                            </Link>
                          </div>
                          <div className="py-1 text-xs text-gray-500">
                            <span className="mr-1">
                              {getMonth(item.createdAt)}
                            </span>
                            {/* <span className="px-[0.1rem]">.</span> */}
                            <span className="ml-1">
                              {item.readingTime} min read
                            </span>
                          </div>
                        </div>
                        <div className="w-full md:w-72 h-full">
                          <img
                            className="max-h-[30vh] w-full md:w-72 object-cover"
                            src={item.postHeaderImage}
                            alt="cardimage"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        <PreLoader />
      )}
    </div>
  );
};

export default BlogTopic;
