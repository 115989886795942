import axiosClient from "api/axios";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const AsideBar = () => {
  const [topicsList, setTopicsList] = useState(null);

  useEffect(() => {
    const getTopics = async () => {
      await axiosClient
        .get("/api/v1/topics?published[eq]=1")
        .then(({ data }) => {
          setTopicsList(data.data);
        })
        .catch((error) => {
          return error;
        });
    };
    getTopics();
  }, []);

  return (
    <aside className="w-full z-20 bg-white md:bg-none md:w-4/12 block sticky top-[55px] md:top-[10vh] self-start pt-3 md:py-4 px-8 shadow-sm md:px-12">
      <div className="flex flex-col md:flex-col w-full">
        <div className=" ml-2 hidden md:flex">
          <h4 className="font-bold">Discover more topics</h4>
        </div>
        {topicsList && (
          <div className="flex flex-row overflow-scroll md:flex-wrap mt-1 px-2 pb-2 md:mt-5">
            {topicsList.map((item, index) => {
              return (
                <div
                  key={index}
                  className="m-1 text-sm bg-gray-200 hover:bg-gray-300 ease-in-out duration-200 min-w-max px-5 py-1 text-gray-900 rounded-3xl text-center"
                >
                  <Link
                    className="flex items-center"
                    to={`/blog/${item.topicName.replace(/\s/g, "-")}?topic=${
                      item.topicId
                    }`}
                  >
                    <p>{item.topicName}</p>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {/* <div>sign up for updates</div> */}
    </aside>
  );
};

export default AsideBar;
