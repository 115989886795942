import { Navigate } from "react-router-dom";
import { useUserStateContext } from "contexts/UserContext";

const ProtectedRoute = ({ children }) => {
  const { user, token } = useUserStateContext();

  if (!token) {
    return <Navigate to="/high-level/login" replace />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
