import { useSearchParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

//app
import PostsCard from "../components/postcard";
import axiosClient from "api/axios";

const ViewPost = () => {
  const [shouldReRender, setShouldRerender] = useState("false");
  //get if post is published or not
  let [searchParams, setSearchParams] = useSearchParams();
  const published = searchParams.get("published");
  const [posts, setPosts] = useState();
  //to change data based on state
  let url_published = null;
  if (published === "true") {
    url_published = "1";
  } else {
    url_published = "0";
  }
  // //get list of all topics
  // const { error, isLoaded, data } = useApiGetRequest(
  //   `/api/posts/${url_published}`
  // );
  const handleRerender = () => {
    setShouldRerender(true);
  };

  useEffect(() => {
    const fetchPosts = () => {
      axiosClient
        .get(`/api/v1/posts?published[eq]=${url_published}`)
        .then(({ data }) => {
          setPosts(data.data);
          setShouldRerender(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    };
    fetchPosts();
  }, [published, shouldReRender]);

  return (
    <div className="">
      <div className="mb-5 flex flex-row justify-between items-center">
        <h4 className="text-[1.8rem] font-bold">
          {published === "true" ? "Published Posts" : "Unpublished Posts"}
        </h4>
        <div>
          <Link
            to="/high-level/my-admin/create-post"
            className="bg-primary-blue px-3 py-2 text-primary-white rounded-full text-sm"
          >
            New Post
          </Link>
        </div>
      </div>
      <div>
        {posts && (
          <PostsCard
            posts={posts}
            published={published}
            onRerender={handleRerender}
          />
        )}
      </div>
    </div>
  );
};

export default ViewPost;
