import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import hljs from "highlight.js";
import "highlight.js/styles/base16/3024.css";

import apiPostImage from "api/useApiPostImage";
const { BaseModule } = ReactQuill.Quill.import("core/module");
//const { CodeBlock } = ReactQuill.Quill.import("formats/code");

// hljs.configure({
//   languages: ["javascript", "ruby", "python", "rust"],
// });

class EditorContainer extends Component {
  componentDidMount() {
    Quill.register(
      {
        "core/module": BaseModule,
        //"formats/code": CodeBlock,
      },
      true
    );
    hljs.configure({
      languages: [
        "javascript",
        "python",
        "c",
        "c++",
        "java",
        "HTML",
        "css",
        "matlab",
      ],
    });
  }

  apiPostNewsImage() {
    // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
  }

  imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append("image", file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      this.quill.insertEmbed(
        range.index,
        "image",
        `${window.location.origin}/images/loaders/placeholder.gif`
      );

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
      const res = await apiPostImage(formData);

      if (res) {
        const url = res.data.url;
        console.log(url);
        // Remove placeholder image
        this.quill.deleteText(range.index, 1);

        // Insert uploaded image
        // this.quill.insertEmbed(range.index, 'image', res.body.image);
        this.quill.insertEmbed(range.index, "image", url);
      } else {
      }
    };
  }

  onEditorChange = (value) => {
    this.setState({
      editorState: value.getContents(),
    });
  };

  modules = {
    syntax: {
      highlight: (text) => hljs.highlightAuto(text).value,
    }, // Include syntax module
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
    // ImageResize: {
    //   modules: ["Resize", "DisplaySize"],
    // },
    // imageCompress: {
    //   quality: 0.7, // default
    //   maxWidth: 50, // default
    //   maxHeight: 50, // default
    //   imageType: "image/jpeg", // default
    //   debug: true, // default
    // },
  };

  render() {
    return (
      <div className="editor">
        <ReactQuill
          value={this.props.value}
          modules={this.modules}
          theme="snow"
          //formats={formats}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default EditorContainer;
