import { useSearchParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

//app
import TopicsCard from "../components/topiccard";
import axiosClient from "api/axios";

const ViewTopic = () => {
  const [shouldReRender, setShouldRerender] = useState("false");

  let [searchParams, setSearchParams] = useSearchParams();
  const published = searchParams.get("published");
  const [topics, setTopics] = useState();
  //to change data based on state
  let url_published = null;
  if (published === "true") {
    url_published = "1";
  } else {
    url_published = "0";
  }
  // //get list of all topics
  // const { error, isLoaded, data } = useApiGetRequest(
  //   `/api/posts/${url_published}`
  // );
  const handleRerender = () => {
    setShouldRerender(true);
  };

  useEffect(() => {
    const fetchTopics = () => {
      axiosClient
        .get(`/api/v1/topics?published[eq]=${url_published}&include-posts=true`)
        .then(({ data }) => {
          setTopics(data.data);
          setShouldRerender(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    };
    fetchTopics();
  }, [published, shouldReRender]);

  return (
    <div>
      <div className="mb-5 flex flex-row justify-between items-center">
        <h4 className="text-[1.8rem] font-bold">
          {published === "true" ? "Published Topics" : "Unpublished Topics"}
        </h4>
        <div>
          <Link
            to="/high-level/my-admin/create-topic"
            className="bg-primary-blue px-3 py-2 text-primary-white rounded-full text-sm"
          >
            New Topic
          </Link>
        </div>
      </div>
      <div>
        {topics && (
          <TopicsCard
            topics={topics}
            published={published}
            onRerender={handleRerender}
          />
        )}
      </div>
    </div>
  );
};

export default ViewTopic;
