import axiosClient from "api/axios";
import axios from "axios";
import { useUserStateContext } from "contexts/UserContext";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Local/internal imports
import EditorContainer from "utils/EditorContainer";

const CreateSubTopic = () => {
  const navigate = useNavigate();

  const { user } = useUserStateContext();

  const [subTopicName, setSubTopicName] = useState("");
  const [selectedTopic, setSelectedTopic] = useState();

  const [creator, setCreator] = useState(user?.user_id || null);
  const [results, setResults] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [topics, setTopics] = useState();
  const [isLoadedTopics, setIsLoadedTopics] = useState(false);

  //fetch list of topics
  useEffect(() => {
    try {
      axiosClient.get("/api/v1/topics").then((response) => {
        setTopics(response.data.data);
        setIsLoadedTopics(true);
      });
    } catch (error) {
      setIsLoadedTopics(false);
      setError(error);
    }
  }, [isLoadedTopics]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreator(user.user_id);

    const data = {
      subTopicName: subTopicName,
      topicId: selectedTopic,
      createdBy: creator,
    };

    setError(null);
    setSuccess(null);
    try {
      await axiosClient.post("/api/v1/subtopics", data).then((response) => {
        if (response.status === 201) {
          setResults(response.data.data);
          setSuccess(response.statusText);
          navigate("/high-level/my-admin/view-sub-topic?published=false");
        }
      });
    } catch (error) {
      const { response } = error;
      setError(response.data.errors);
    }
  };

  return (
    <div className="flex flex-col">
      {error && (
        <div className="rounded-lg p-2 alert">
          {Object.keys(error).map((key) => (
            <p key={key}>{error[key][0]}</p>
          ))}
        </div>
      )}
      {success && <div className="success-msg">{`${success}`}</div>}

      <div className="border-b border-gray-400 pb-5">
        <h5 className="text-[1.5rem]">New Sub-topic</h5>
      </div>

      <form className="mt-6">
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block mb-2 font-medium text-gray-900 "
          >
            Subtopic Name
          </label>
          <input
            type="text"
            id="topic_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 "
            placeholder="Enter subtopic name"
            value={subTopicName}
            onChange={(e) => setSubTopicName(e.target.value)}
            required
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="topics"
            className="block mb-2  font-medium text-gray-900 "
          >
            Select your topic
          </label>
          <select
            id="topics"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => setSelectedTopic(e.target.value)}
            value={selectedTopic}
          >
            <option>Select topic</option>
            {isLoadedTopics &&
              topics.map((item, index) => {
                return (
                  <option key={index} value={item.topicId}>
                    {item.topicName}
                  </option>
                );
              })}
          </select>
        </div>

        <button
          type="submit"
          className="text-white bg-primary-blue hover:bg-bg-footer-blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-primary-blue dark:hover:bg-footer-blue dark:focus:ring-blue-800"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateSubTopic;
