// import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "assets/css/App.css";
import MainLayout from "layout/MainLayout";
import Home from "pages/home";
import Contact from "pages/contact";
import About from "pages/about";
import PageNotFound from "pages/pagenotfound";
import Post from "pages/post";
import Topic from "pages/topics";
import Login from "pages/auth/Login";
import LogOut from "pages/auth/Logout";
import Register from "pages/auth/Register";
import ProtectedRoute from "components/ProtectedRoute";
import AdminPage from "pages/admin";
import EditPost from "pages/admin/posts/editpost";
import ViewPost from "pages/admin/posts/viewposts";
import EditTopic from "pages/admin/topics/edittopic";
import ViewTopic from "pages/admin/topics/viewtopic";
import { UserContextProvider } from "contexts/UserContext";
import { AppContextProvider } from "contexts/AppContext";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <AppContextProvider>
          <UserContextProvider>
            <BrowserRouter>
              <MainLayout>
                <Routes>
                  <Route path="/high-level/login" element={<Login />} />
                  <Route path="/high-level/logout" element={<LogOut />} />
                  <Route path="/high-level/register" element={<Register />} />

                  <Route path="/" element={<Home />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/blog" element={<Topic />} />
                  <Route path="/blog/:topic" element={<Topic />} />
                  <Route
                    path="/blog/:topic/:post"
                    element={<Post />} /* this is the route /topic/:post-id*/
                  />

                  <Route
                    path="/high-level/my-admin/*"
                    element={
                      <ProtectedRoute>
                        <AdminPage />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="/high-level/my-admin/home"
                    index={true}
                    element={
                      <ProtectedRoute>
                        <EditPost />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/high-level/my-admin/edit-post"
                    element={
                      <ProtectedRoute>
                        <EditPost />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/high-level/my-admin/view-post"
                    element={
                      <ProtectedRoute>
                        <ViewPost />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/high-level/my-admin/edit-topic"
                    element={
                      <ProtectedRoute>
                        <EditTopic />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/high-level/my-admin/view-topic"
                    element={
                      <ProtectedRoute>
                        <ViewTopic />
                      </ProtectedRoute>
                    }
                  />
                </Route> */}
                  {/* <Route
                  path="/high-level/my-admin/edit-post"
                  element={
                    <ProtectedRoute>
                      <EditPost />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/high-level/my-admin/view-post"
                  element={
                    <ProtectedRoute>
                      <ViewPost />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/high-level/my-admin/edit-topic"
                  element={
                    <ProtectedRoute>
                      <EditTopic />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/high-level/my-admin/view-topic"
                  element={
                    <ProtectedRoute>
                      <ViewTopic />
                    </ProtectedRoute>
                  }
                /> */}

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </MainLayout>
            </BrowserRouter>
          </UserContextProvider>
        </AppContextProvider>
      </HelmetProvider>
      {/* <Helmet>
        <meta
          name="description"
          content="A programming and developer blog covering topics such as web development, software engineering, and programming languages."
        />
        <meta
          name="keywords"
          content="programming, web development, software engineering,software development, IT,technology, developer blog, coding, programming languages, algorithms, data structures, android,"
        />
      </Helmet> */}
    </div>
  );
}

export default App;
