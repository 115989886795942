import { useParams, useSearchParams, Navigate } from "react-router-dom";
import BlogTopic from "./single-topic";
import AllTopics from "./all-topics";

const Topic = () => {
  const { topic } = useParams();
  //check if the name of the topic exists
  //const [topicsParam, setTopicsParam] = useSearchParams();
  //const topic = topicsParam.get("topic");
  //call db and check if topic exists

  return (
    <div className="">
      {topic ? <BlogTopic topic={topic} /> : <AllTopics />}
    </div>
  );
};

export default Topic;
