import { NavLink } from "react-router-dom";

const AdminSideNav = () => {
  return (
    <div className="sticky top-9 mx-10 mt-7 h-full border-r flex space-y-5 flex-col ">
      <div className="my-3"></div>
      <div className="my-3">
        <NavLink
          to="/high-level/my-admin/home"
          className=" hover:text-gray-500 ease-in-out duration-150"
        >
          Home
        </NavLink>
      </div>
      <div>
        <h2 className="text-lg font-bold">Posts</h2>
        <div className="my-3 ml-5">
          <NavLink
            to="/high-level/my-admin/view-post?published=true"
            className=" hover:text-gray-500 ease-in-out duration-150"
          >
            Published Posts
          </NavLink>
        </div>
        <div className="my-3 ml-5">
          <NavLink
            to="/high-level/my-admin/view-post?published=false"
            className=" hover:text-gray-500 ease-in-out duration-150"
          >
            Unpublished Posts
          </NavLink>
        </div>
      </div>

      <div>
        <h2 className="text-lg font-bold">Topics</h2>
        <div className="my-3 ml-5">
          <NavLink
            to="/high-level/my-admin/view-topic?published=true"
            className=" hover:text-gray-500 ease-in-out duration-150"
          >
            Published Topics
          </NavLink>
        </div>
        <div className="my-3 ml-5">
          <NavLink
            to="/high-level/my-admin/view-topic?published=false"
            className=" hover:text-gray-500 ease-in-out duration-150"
          >
            UpPublished Topics
          </NavLink>
        </div>
      </div>

      <div>
        <h2 className="text-lg font-bold">SubTopics</h2>
        <div className="my-3 ml-5">
          <NavLink
            to="/high-level/my-admin/view-sub-topic?published=true"
            className=" hover:text-gray-500 ease-in-out duration-150"
          >
            Published subTopics
          </NavLink>
        </div>
        <div className="my-3 ml-5">
          <NavLink
            to="/high-level/my-admin/view-sub-topic?published=false"
            className=" hover:text-gray-500 ease-in-out duration-150"
          >
            UnPublished SubTopics
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AdminSideNav;
