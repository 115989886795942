const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function formatFullDate(dateString) {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return `${monthNames[month]} ${day.toString().padStart(2, "0")}, ${year}`;
}
function getMonth(dateString) {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  return `${monthNames[month]}`;
}

export { formatFullDate, getMonth };
