import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import axiosClient from "api/axios";

const TopicsCard = ({ topics, published, onRerender }) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleDelete = async (e) => {
    e.preventDefault();
    const topic = e.target.getAttribute("data-topic-id");

    setError(null);
    setSuccess(null);
    try {
      await axiosClient.delete(`/api/v1/topics/${topic}`).then((response) => {
        if (response) {
          setSuccess(response.data.msg);
          //window.location.reload();
          onRerender();

          // if (response.data) {
          //   navigate("/high-level/my-admin/view-topic");
          // }
        }
      });
    } catch (error) {
      setError(error.message);
    }
  };
  const handlePublish = async (e) => {
    e.preventDefault();
    const topic = e.target.getAttribute("data-topic-id");

    setError(null);
    setSuccess(null);
    try {
      const data = {
        published: 1,
      };
      await axiosClient
        .patch(`/api/v1/topics/${topic}`, data)
        .then((response) => {
          if (response) {
            setSuccess(response.data.msg);
            //window.location.reload();
            // if (response.data) {
            //   navigate("/high-level/my-admin/view-topic");
            // }
            onRerender();
          }
        });
    } catch (error) {
      setError(error.message);
    }
  };
  const handleUnpublish = async (e) => {
    e.preventDefault();
    const topic = e.target.getAttribute("data-topic-id");

    setError(null);
    setSuccess(null);
    try {
      const data = {
        published: 0,
      };
      await axiosClient
        .patch(`/api/v1/topics/${topic}`, data)
        .then((response) => {
          if (response) {
            setSuccess(response.data.msg);
            //window.location.reload();
            // if (response.data) {
            //   navigate("/high-level/my-admin/view-topic");
            // }
            onRerender();
          }
        });
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div className="w-full h-max ">
      {error && <div className="error-msg">{`${error}`}</div>}
      {success && <div className="success-msg">{`${success}`}</div>}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-4">
        <div className="flex items-center justify-between pb-4">
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for items"
            />
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">Id</div>
              </th>
              <th scope="col" className="px-6 py-3">
                Topic name
              </th>

              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {topics.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ease-in-out duration-100"
                >
                  <td className="w-4 p-4">
                    <div className="flex items-center">{index}</div>
                  </td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {item.topicName}
                  </th>

                  <td className="px-6 py-4  flex flex-row">
                    <div className="flex flex-row justify-center items-center">
                      <Link
                        to={`/high-level/my-admin/edit-topic?topic_id=${item.topicId}`}
                        className="font-medium mx-3 py-3 text-primary-blue dark:text-blue-500 hover:underline hover:text-white ease-in-out duration-150"
                      >
                        Edit
                      </Link>
                      {published === "false" && (
                        <button
                          onClick={handlePublish}
                          data-topic-id={item.topicId}
                          className="font-medium mx-3 text-primary-white rounded-full px-4 py-2 bg-primary-blue dark:text-blue-500 hover:text-gray-200 hover:bg-primary-black ease-in-out duration-150"
                        >
                          Publish
                        </button>
                      )}
                    </div>
                    <div className="flex flex-row justify-center">
                      <button
                        onClick={handleDelete}
                        data-topic-id={item.topicId}
                        className="font-medium mx-3 py-3 text-primary-red dark:text-blue-500 hover:underline hover:text-white ease-in-out duration-150"
                      >
                        Delete
                      </button>

                      {published === "true" && (
                        <button
                          onClick={handleUnpublish}
                          data-topic-id={item.topicId}
                          className="font-medium mx-3 text-primary-white rounded-full px-4 py-2 bg-primary-red dark:text-blue-500 hover:text-gray-200 hover:bg-primary-black ease-in-out duration-150"
                        >
                          UnPublish
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopicsCard;
