const About = () => {
  return (
    <div className="min-h-[70vh] pt-16 mb-5 mx-16 divide-y divide-gray-200 dark:divide-gray-300">
      <div className=" space-y-2 pt-6 pb-8 md:space-y-5">
        <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-800 sm:text-4xl sm:leading-10 md:text-6xl">
          About
        </h1>
      </div>
      <div className="items-start space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
        <div className="flex flex-col items-center pt-8">
          <img className="h-48 w-48 rounded-full" src={"avatar"} alt="" />
          <h3 class="pt-4 pb-2 text-2xl font-bold leading-8 tracking-tight">
            BespectacledDev
          </h3>
          <div class="text-gray-500 dark:text-gray-400">Tech & Software</div>
        </div>
        <div className="flex-row font-oxygen text-gray-800 justify-center items-center prose max-w-none pt-8 pb-8 dark:prose-dark xl:col-span-2">
          <p className="mb-3">
            Welcome to BespectacledDev, a personal blog where I share my journey
            and insights as a developer.
          </p>
          <p className="mb-3">
            As a bespectacled developer, I navigate the world of coding with a
            unique perspective. With my trusty glasses and a passion for
            technology, I dive into the ever-evolving world of software
            development.
          </p>
          <p className="mb-3">
            Through this blog, I aim to provide valuable content, tutorials,
            tips, and experiences that will inspire fellow developers and tech
            enthusiasts. I cover a wide range of topics, including web
            development, programming languages, frameworks, tools, and best
            practices.
          </p>
          <p>
            Join me as I share my thoughts, discoveries, and challenges
            encountered on my coding adventures. Whether you are a seasoned
            developer or just starting your journey, there's something here for
            you.
          </p>
          <p>
            Thank you for visiting BespectacledDev. Let's embark on this coding
            journey together and see the world through the lenses of a
            passionate developer. Happy coding!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
