import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { AppStateContext } from "contexts/AppContext";
import axiosClient from "api/axios";
import { useUserStateContext } from "contexts/UserContext";

const EditSubTopic = () => {
  //constext
  const { setNotification } = AppStateContext();
  const { user } = useUserStateContext();

  //get id from url
  const [subtopic_id] = useSearchParams();
  const navigate = useNavigate();
  const id = subtopic_id.get("subtopic_id");
  //make get req

  const [subTopicName, setSubTopicName] = useState("");
  const [creator, setCreator] = useState(user?.user_id || null);
  const [error, setError] = useState("");

  const [topics, setTopics] = useState();
  const [isLoadedTopics, setIsLoadedTopics] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState();

  //fetch list of topics
  useEffect(() => {
    try {
      axiosClient.get("/api/v1/topics").then((response) => {
        setTopics(response.data.data);
        setIsLoadedTopics(true);
      });
    } catch (error) {
      setIsLoadedTopics(false);
      setError(error);
    }
  }, [isLoadedTopics]);

  //fetch subtopic etails to edit
  useEffect(() => {
    const fetchSubTopics = async () => {
      await axiosClient
        .get(`/api/v1/subtopics/${id}`)
        .then(({ data }) => {
          setSelectedTopic(data.data.topic.topicId);
          setSubTopicName(data.data.subTopicName);
        })
        .catch((error) => {
          //console.log(error);
          const { response } = error;
          setError(response.data.error);
        });
    };

    fetchSubTopics();
  }, []);

  const handleSubmit = async (e) => {
    setCreator(user.user_id);
    e.preventDefault();
    const data = {
      subTopicName: subTopicName,
      topicId: selectedTopic,
      createdBy: creator,
    };
    await axiosClient
      .put(`/api/v1/subtopics/${id}`, data)
      .then((response) => {
        if (response) {
          navigate("/high-level/my-admin/view-sub-topic?published=true");
        }
        setNotification("Sub-topic updated successfully");
      })
      .catch((error) => {
        setError(error.message);
        //console.log(error.message);
      });
  };

  return (
    <div className="flex flex-col">
      <div>{error && <div className="error-msg">{error}</div>}</div>
      <div className="border-b border-gray-400 pb-5">
        <h5 className="text-[1.5rem]">Sub-topic Details</h5>
      </div>

      <form className="mt-6">
        <div className="mb-6">
          <label
            htmlFor="subtopic_name"
            className="block mb-2 font-medium text-gray-900 "
          >
            Name
          </label>
          <input
            type="text"
            id="subtopic_name"
            name="subtopic_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter subtopic name"
            value={subTopicName}
            onChange={(e) => {
              setSubTopicName(e.target.value);
            }}
            required
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="topics"
            className="block mb-2  font-medium text-gray-900 "
          >
            Select your topic
          </label>
          <select
            id="topics"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => setSelectedTopic(e.target.value)}
            value={selectedTopic}
          >
            <option>Select topic</option>
            {isLoadedTopics &&
              topics.map((item, index) => {
                return (
                  <option key={index} value={item.topicId}>
                    {item.topicName}
                  </option>
                );
              })}
          </select>
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          className="text-white bg-primary-blue hover:bg-bg-footer-blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-primary-blue dark:hover:bg-footer-blue dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EditSubTopic;
