import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";

//import { useApiGetRequest } from "api/useApiGetRequest";
import Hero from "./hero";
import LatestSection from "./latest";
import LatestHome from "./popular";
import axiosClient from "api/axios";
import PreLoader from "components/Preloader";

const Home = () => {
  const [posts, setPosts] = useState(null);
  const [latestPosts, setLatestPosts] = useState();
  const [remPosts, setRemPosts] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [nextPageLink, setNextPageLink] = useState(null);

  const handleLoadMore = () => {
    if (nextPageLink != null) {
      axiosClient.get(nextPageLink).then(({ data }) => {
        setRemPosts((remPosts) => remPosts.concat(data.data));
        setNextPageLink(data.links.next);
      });
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      await axiosClient
        .get("/api/v1/posts?published[eq]=1")
        .then(({ data }) => {
          if (data.links.next !== null) {
            setNextPageLink(data.links.next);
          } else {
            setNextPageLink(null);
          }
          setPosts(data.data);
          setLatestPosts(data.data.slice(0, 7));
          setRemPosts(data.data.slice(8));
          setIsLoading(false);
        })
        .catch((e) => {});
    };
    fetchPosts();
  }, []);

  //latest articles for the latest section
  return (
    <div className="block relative min-h-[80vh] ">
      <Helmet>
        <meta
          name="description"
          content="A programming and developer blog covering topics such as web development, software engineering, and programming languages."
        />
        <meta
          name="keywords"
          content="programming, web development, software engineering,software development, IT,technology, developer blog, coding, programming languages, algorithms, data structures, android,"
        />
      </Helmet>
      <Hero />
      {!posts && <PreLoader />}
      {posts && (
        <>
          <LatestHome posts={latestPosts} />
          <LatestSection
            posts={remPosts}
            loadMore={handleLoadMore}
            nextPage={nextPageLink}
          />
        </>
      )}
    </div>
  );
};

export default Home;
