import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="mt-16 px-2 md:px-10 md:min-h-[70vh]  relative">
      <div className="mt-10 h-full flex flex-row absolute top-1/2 left-1/2 transform md:-translate-y-1/3 -translate-x-1/2">
        <div>
          <div className="font-bold md:mb-3 mb-2">
            <p className="text-4xl">404</p>
            <p className="text-lg">Ooops!</p>
            <p className="text-lg">Page Not Found</p>
          </div>
          <div className="text-sm text-gray-500 font-merriweathersans font-normal">
            <p>This page doesn't exist or was removed!</p>
            <p>We suggest you go back to home</p>
          </div>
          <div className="md:mt-5 mt-5">
            <Link
              to="/"
              className="rounded-full px-4 text-sm py-2 shadow-lg border text-primary-black font-merriweathersans hover:bg-primary-black hover:text-primary-white hover:border-none ease-in-out duration-300"
            >
              Back to Home
            </Link>
          </div>
          <div></div>
        </div>
        <div>
          {/* Image goes here */}
          <img src="" alt="" />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
