import { useParams } from "react-router-dom";
//import parse from "html-react-parser";
import hljs from "highlight.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";

import BGImage from "assets/images/image1.jpg";
import "./post.css";
import AsideBar from "components/AsideBar";
import { formatFullDate } from "utils/formatDate";
import axiosClient from "api/axios";
import PreLoader from "components/Preloader";
const Post = () => {
  //Get url parameters
  const { post } = useParams();
  // const { error, isLoaded, data } = useApiGetRequest(`/api/posts/${post}`);

  const [article, setArticle] = useState();
  const [subtopics, setSubtopics] = useState();

  const fetchData = async () => {
    await axiosClient
      .get(`/api/v1/posts/${post}`)
      .then(({ data }) => {
        setArticle(data.data);
        setSubtopics(JSON.parse(data.data.subtopics));
      })
      .catch((error) => {
        return error;
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    hljs.highlightAll();
  });
  return (
    <div>
      <Helmet>
        {article && (
          <>
            <meta name="description" content={article.postDescription} />
            <meta name="keywords" content={article.postKeywords} />
          </>
        )}
      </Helmet>
      <div className="pt-[5vh] flex ">
        <section className="flex flex-col relative md:flex-row-reverse h-auto pt-6 mt-4 w-full">
          <AsideBar />
          <div className="w-full md:w-8/12 relative h-auto px-6 md:pl-10 mx-0 mb-2 ">
            {article ? (
              <div className="md:ml-[7vw] mt-2  h-max flex-col w-auto">
                <div className="flex flex-row items-center align-middle">
                  <div className="h-3 w-3 rounded-full mr-2 bg-primary-red"></div>
                  <p className="text-sm font-bold font-lato">
                    {article.topic.topicName}
                  </p>
                </div>
                <h2 className="single-post-title  text-[1.5rem] md:text-[2rem] font-semibold">
                  {article.postTitle}
                </h2>
                <div className="flex flex-col mt-1 mb-1">
                  <p className="text-sm">
                    by {article.user.firstName} {article.user.lastName}
                  </p>
                  <p className="mb-[1vw] text-gray-400 text-xs md:text-sm font-lato text-semibold">
                    Published {formatFullDate(article.createdAt)}
                  </p>
                </div>
                <div className="flex flex-row mb-2">
                  {subtopics &&
                    Object.values(subtopics).map((item, index) => (
                      <div
                        className="px-3 py-1 shadow-sm rounded-full text-xs text-gray-800 bg-gray-100 hover:bg-gray-200 mr-1"
                        key={index}
                      >
                        <Link to={`/blog/search/?subtopic=${item.subTopicId}`}>
                          {item.subTopicName}
                        </Link>
                      </div>
                    ))}
                </div>
                <div className="my-[1vh] w-full h-[50vh] overflow-hidden">
                  <img
                    className="h-full w-full object-cover overflow-hidden"
                    src={article.postHeaderImage}
                    alt=""
                  />
                </div>
                <div className="relative article-section flex-wrap h-auto font-lato mt-5 ql-snow">
                  {/* {parse(article.post_body)} */}
                  <div
                    className="ql-editor !px-0"
                    dangerouslySetInnerHTML={{ __html: article.postBody }}
                  ></div>
                </div>
              </div>
            ) : (
              <PreLoader />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
export default Post;
