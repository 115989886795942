import { Route, Routes } from "react-router-dom";
import AdminSideNav from "./components/AdminSideNav";
import ViewPost from "./posts/viewposts";
import EditPost from "./posts/editpost";
import ViewTopic from "./topics/viewtopic";
import EditTopic from "./topics/edittopic";
import AnalyticsPage from "./analytics/anaylitcs";
import CreateTopic from "./topics/createtopic";
import CreatePost from "./posts/createpost";
import { useEffect } from "react";
import axiosClient from "api/axios";
import { useUserStateContext } from "contexts/UserContext";
import CreateSubTopic from "./subtopics/createsubtopic";
import ViewSubTopic from "./subtopics/viewsubtopic";
import EditSubTopic from "./subtopics/editsubtopic";

const AdminPage = () => {
  const { setUser, user } = useUserStateContext();

  useEffect(() => {
    axiosClient.get("/api/user").then(({ data }) => {
      setUser(data);
    });
  }, []);

  return (
    <div className="w-full h-full px-4  flex flex-col relative">
      {/* <div className="pt-3 pl-10">
        <p className="text-2xl font-medium ml-5">Admin Panel</p>
      </div> */}
      <div className="flex pt-3 pr-10 relative">
        <div className="sticky top-10 h-screen w-80  p-5 pt-0 ">
          <AdminSideNav />
        </div>
        <div className="flex-1 pt-16 p-5">
          <>
            <div>
              {user && (
                <p className="text-xl bold">
                  Welcome back {`${user.first_name} ${user.last_name}`}
                </p>
              )}

              <Routes>
                <Route path="home" element={<AnalyticsPage />}></Route>
                {/* Posts */}
                <Route path="create-post" element={<CreatePost />}></Route>
                <Route path="view-post" element={<ViewPost />}></Route>
                <Route path="edit-post" element={<EditPost />}></Route>
                {/* Topics */}
                <Route path="create-topic" element={<CreateTopic />}></Route>
                <Route path="view-topic" element={<ViewTopic />}></Route>
                <Route path="edit-topic" element={<EditTopic />}></Route>
                {/* Subtopics */}
                <Route
                  path="create-sub-topic"
                  element={<CreateSubTopic />}
                ></Route>
                <Route path="view-sub-topic" element={<ViewSubTopic />}></Route>
                <Route path="edit-sub-topic" element={<EditSubTopic />}></Route>
              </Routes>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
