import image from "assets/images/image1.jpg";
import { Link } from "react-router-dom";
import { formatFullDate } from "utils/formatDate";

const LatestHome = ({ posts }) => {
  return (
    <div>
      <div className="flex justify-center p-5 ">
        <p className="text-xl font-extrabold pb-1 border-b w-max border-black font-maven">
          Latest on BespectacledDev
        </p>
      </div>
      {posts && (
        <div className="w-full flex flex-col md:flex-row md:px-10 px-4">
          <div className="w-full flex flex-col h-auto md:flex-row md:w-7/12 ">
            <div className="w-full md:w-1/2 p-4 border-r border-gray-200 ">
              {/* first two boxes */}
              <div className="border-b border-gray-200 pb-4">
                <div className="flex items-center mb-1">
                  <Link
                    className="flex items-center mt-1 mb-1"
                    to={`/blog/${posts[0].topic.topicName.replace(/\s/g, "-")}`}
                  >
                    <div className="h-3 w-3 rounded-full mr-2 bg-primary-red"></div>
                    <p className="text-xs font-bold uppercase">
                      {posts[0].topic.topicName}
                    </p>
                  </Link>
                </div>
                <div>
                  <Link
                    to={`/blog/${posts[0].topic.topicName.replace(
                      /\s/g,
                      "-"
                    )}/${posts[0].postId}`}
                  >
                    <h5 className="text-lg font-bold">{posts[0].postTitle}</h5>
                    <p className="text-sm mt-3">{posts[0].postDescription}</p>
                  </Link>
                </div>
                <div className="flex flex-row items center content-center mt-4 ">
                  <div className="h-8 w-8 overflow-hidden rounded-lg">
                    <img
                      className="h-full w-full object-cover"
                      src={posts[0].user.userProfileImage}
                      alt=""
                    />
                  </div>
                  <div className="ml-2 flex flex-col justify-between">
                    <p className="text-xs font-bold text-gray-800">
                      <span className="mr-1">{posts[0].user.firstName}</span>
                      <span>{posts[0].user.lastName}</span>
                    </p>
                    <p className="text-[0.6rem] text-gray-600">
                      {formatFullDate(posts[0].createdAt)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-gray-200 pb-4">
                <div className="flex items-center mt-6 mb-2">
                  <Link
                    className="flex items-center mt-1 mb-1"
                    to={`/blog/${posts[1].topic.topicName.replace(/\s/g, "-")}`}
                  >
                    <div className="h-3 w-3 rounded-full mr-2 bg-primary-red"></div>
                    <p className="text-xs font-bold uppercase">
                      {posts[1].topic.topicName}
                    </p>
                  </Link>
                </div>
                <div>
                  <Link
                    to={`/blog/${posts[1].topic.topicName.replace(
                      /\s/g,
                      "-"
                    )}/${posts[1].postId}`}
                  >
                    <h5 className="text-lg font-bold">{posts[1].postTitle}</h5>
                    <p className="text-xs font-bold mt-3 text-gray-600">
                      by <span className="mr-1">{posts[1].user.firstName}</span>
                      <span>{posts[1].user.lastName}</span>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-full md:w-5/6 p-4 border-r border-gray-200">
              {/* this is the second box */}
              <div className="border-b border-gray-200 pb-4">
                <div className="w-full h-[30vh] mb-3">
                  <img
                    className="w-full h-full object-cover"
                    src={posts[2].postHeaderImage}
                    alt=""
                  />
                </div>
                <div>
                  <Link
                    to={`/blog/${posts[2].topic.topicName.replace(
                      /\s/g,
                      "-"
                    )}/${posts[2].postId}`}
                  >
                    <h5 className="text-lg font-bold">{posts[2].postTitle}</h5>
                    <p className="text-sm mt-3">{posts[2].postDescription}</p>
                  </Link>
                </div>
                <div className="flex flex-row items center content-center mt-4 ">
                  <div className="h-8 w-8 overflow-hidden rounded-lg">
                    <img
                      className="h-full w-full object-cover"
                      src={posts[2].user.userProfileImage}
                      alt=""
                    />
                  </div>
                  <div className="ml-2 flex flex-col justify-between">
                    <p className="text-xs font-bold">
                      <span className="mr-1">{posts[2].user.firstName}</span>
                      <span>{posts[2].user.lastName}</span>
                    </p>
                    <p className="text-[0.6rem] text-gray-600">
                      {formatFullDate(posts[2].createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full flex flex-col md:flex-row md:w-5/12 ">
            {/**Thirds box */}
            <div className="md:w-1/2 p-4 border-r border-gray-200">
              <div className="border-b border-gray-200 pb-4 mb-3">
                <div className="w-full md:h-[20vh] mb-3">
                  <img
                    className="w-full h-full object-cover"
                    src={posts[3].postHeaderImage}
                    alt=""
                  />
                </div>
                <Link
                  to={`/blog/${posts[3].topic.topicName.replace(/\s/g, "-")}/${
                    posts[3].postId
                  }`}
                >
                  <div>
                    <h5 className="font-bold">{posts[3].postTitle}</h5>
                  </div>
                </Link>
              </div>
              <div className="border-b border-gray-200 pb-4">
                <div className="w-full md:h-[20vh] mb-3">
                  <img
                    className="w-full h-full object-cover"
                    src={posts[4].postHeaderImage}
                    alt=""
                  />
                </div>
                <Link
                  to={`/blog/${posts[4].topic.topicName.replace(/\s/g, "-")}/${
                    posts[4].postId
                  }`}
                >
                  <div>
                    <h5 className="font-bold">{posts[4].postTitle} </h5>
                  </div>
                </Link>
              </div>
            </div>
            {/**Fourth box */}
            <div className="md:w-1/2 p-3">
              <div className="border-b border-gray-200 pb-4">
                <div className="flex items-center mb-1">
                  <Link
                    className="flex items-center mt-1 mb-1"
                    to={`/blog/${posts[5].topic.topicName.replace(/\s/g, "-")}`}
                  >
                    <div className="h-3 w-3 rounded-full mr-2 bg-primary-red"></div>
                    <p className="text-xs font-bold uppercase">
                      {posts[5].topic.topicName}
                    </p>
                  </Link>
                </div>
                <div>
                  <Link
                    to={`/blog/${posts[5].topic.topicName.replace(
                      /\s/g,
                      "-"
                    )}/${posts[5].postId}`}
                  >
                    <h5 className="text-lg font-bold">{posts[5].postTitle}</h5>
                    <p className="text-sm mt-3">{posts[5].postDescription}</p>
                  </Link>
                </div>
                <p className="text-xs font-bold mt-3 text-gray-600">
                  by <span className="mr-1">{posts[5].user.firstName}</span>
                  <span>{posts[5].user.lastName}</span>
                </p>
              </div>
              <div className="border-b border-gray-200 pb-4">
                <div className="flex items-center mt-6 mb-2">
                  <Link
                    className="flex items-center mt-1 mb-1"
                    to={`/blog/${posts[6].topic.topicName.replace(/\s/g, "-")}`}
                  >
                    <div className="h-3 w-3 rounded-full mr-2 bg-primary-red"></div>
                    <p className="text-xs font-bold uppercase">
                      {posts[6].topic.topicName}
                    </p>
                  </Link>
                </div>
                <div>
                  <Link
                    to={`/blog/${posts[6].topic.topicName.replace(
                      /\s/g,
                      "-"
                    )}/${posts[6].postId}`}
                  >
                    <h5 className="text-lg font-bold">{posts[6].postTitle}</h5>
                    <div className="flex flex-row items center content-center mt-4 ">
                      <div className="h-8 w-8 overflow-hidden rounded-lg">
                        <img
                          className="h-full w-full object-cover"
                          src={posts[6].user.userProfileImage}
                          alt=""
                        />
                      </div>
                      <div className="ml-2 flex flex-col justify-between">
                        <p className="text-xs font-bold text-gray-800">
                          <span className="mr-1">
                            {posts[6].user.firstName}
                          </span>
                          <span>{posts[6].user.lastName}</span>
                        </p>
                        <p className="text-[0.6rem] text-gray-600">
                          {formatFullDate(posts[6].createdAt)}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LatestHome;
