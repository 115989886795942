import { useUserStateContext } from "contexts/UserContext";
import axiosClient from "api/axios";
import { useNavigate } from "react-router-dom";

const LogOut = () => {
  const { setToken, setUser, setIsAuth } = useUserStateContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    axiosClient.post("/api/logout").then(() => {
      setUser({});
      setToken(null);
      setIsAuth(false);
      navigate("/high-level/login");
    });
  };

  // useEffect(() => {
  //   sessionStorage.removeItem("accessToken");
  //   logout();
  // }, []);

  return (
    <div>
      <div className="min-h-[66vh] pt-24 mb-5 mx-16 divide-y divide-gray-200 dark:divide-gray-300">
        <div className=" space-y-2 pt-6 pb-8 md:space-y-5">
          <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-700 sm:text-4xl sm:leading-10 md:text-6xl">
            Sign Out
          </h1>
        </div>
        <button onClick={handleLogout}>Sign out</button>
      </div>
    </div>
  );
};

export default LogOut;
