import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useState } from "react";

function MenuButton() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleMenuBtnClick = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <div className="inline-flex border border-gray-500 rounded-md">
      <div className="relative">
        <button
          onClick={handleMenuBtnClick}
          type="button"
          className="inline-flex items-center justify-center h-full px-2 text-gray-600  hover:text-gray-700 rounded-r-md shadow-lg "
        >
          <BsThreeDotsVertical
            size="20px"
            className="text-primary-blue cursor-pointer"
          />
        </button>

        {toggleMenu && (
          <div className="absolute right-0 z-10 w-56 mt-5 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg">
            <div className="p-2">
              <Link
                to="#"
                onClick={handleMenuBtnClick}
                className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
              >
                Profile
              </Link>
              <Link
                to="/high-level/my-admin"
                onClick={handleMenuBtnClick}
                className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
              >
                Admin
              </Link>
              <Link
                to="/high-level/logout"
                onClick={handleMenuBtnClick}
                className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
              >
                Logout
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MenuButton;
