import axiosClient from "api/axios";
import { createContext, useState, useEffect, useContext } from "react";

const UserContext = createContext({
  user: null,
  token: null,
  setUser: () => {},
  setToken: () => {},
});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuth, setIsAuth] = useState(null);
  // const [token, _setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));

  const [token, _setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));

  //get access token from auth laravel
  useEffect(() => {
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [token]);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("ACCESS_TOKEN", token);
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
    }
  };

  const login = (user) => {
    setUser(user);
    setIsAuth(true);
  };

  //function to handle logout
  const logout = () => {
    setUser(null);
    setIsAuth(false);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        setToken,
        setUser,
        isAuth,
        setIsAuth,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserStateContext = () => useContext(UserContext);
