import { createContext, useState, useEffect, useContext } from "react";

const AppContext = createContext({
  notification: null,
  setNotification: () => {},
});

export const AppContextProvider = ({ children }) => {
  const [notification, _setNotification] = useState();

  const setNotification = (message) => {
    _setNotification(message);

    setTimeout(() => {
      _setNotification("");
    }, 5000);
  };
  return (
    <AppContext.Provider value={{ notification, setNotification }}>
      {children}
    </AppContext.Provider>
  );
};

export const AppStateContext = () => useContext(AppContext);
